<template>
  <div class="claimlist">
    <div class="claimlist-cont">
      <div class="claim-title">Claim List</div>
      <div class="search-box">
        <a-card>
          <a-form :model="form" layout="vertical" ref="formRef">
            <a-row :gutter="[20, 20]">
              <a-col :span="5">
                <a-form-item field="status" label="Claim Status">
                  <a-select v-model="form.status" multiple :max-tag-count="1" allow-clear>
                    <a-option :value="k" v-for="item, k in claimStatus" :key="item">{{ item }}</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item field="reason" label="Reason">
                  <a-select v-model="form.reason" multiple :max-tag-count="1" allow-clear>
                    <a-option v-for="val, k, i in reason" :key="k" :value="i + 1">
                      {{ val }}
                    </a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item field="tracking" label="Tracking">
                  <a-input v-model="form.tracking" placeholder="E.g 0905202229387461" />
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item field="createTime" label="Create Time">
                  <a-config-provider :locale="enUS">
                    <a-range-picker v-model="form.createTime" :placeholder="['From', 'To']" format="MM/DD/YYYY" />
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="4" class="operate">
                <a-space>
                  <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset"
                    cancel-text="Cancel" @ok="reset" class="warnPop">
                    <a-button shape="round">Reset</a-button>
                  </a-popconfirm>
                  <a-button type="primary" shape="round" @click="search">Search</a-button>
                </a-space>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
      <div class="result-til">Search Result</div>
      <div class="table-data">
        <a-config-provider :locale="enUS">
          <a-table :columns="listColumns" :data="listData.value" :bordered="false" :row-selection="rowSelection"
            :pagination="false" @select-all="changmsg" @select="selectVal"
            :scroll="{ x: tableWidth, y: tableHeight.value }" :selected-keys="selectArr.val" :loading="loading">
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 50 + 'px' }" class="empty-cont">
                <img src="../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 35 + 'px' }">
              </div>
            </template>
            <template #claimstatus="{ record }">
              <a-tag color="gold" v-if="record.status === 1">{{
                record.claimstatus
              }}</a-tag>
              <a-tag color="orange" v-if="record.status === 2">{{
                record.claimstatus
              }}</a-tag>
              <a-tag color="red" v-if="record.status === 4">{{
                record.claimstatus
              }}</a-tag>
              <a-tag color="green" v-if="record.status === 3">{{
                record.claimstatus
              }}</a-tag>
              <a-tag v-if="record.status === 6">{{
                record.claimstatus
              }}</a-tag>
            </template>
            <template #trackingid="{ record }">
              <div v-if="record.trackingid">
                {{ record.trackingid }}
                <a-tooltip content="Copy">
                  <icon-copy class="margin-l-10" @click.stop="copyVal(record.trackingid)" />
                </a-tooltip>
              </div>
              <span v-else>N/A</span>
            </template>
            <template #evidence="{ record }">
              <a target="_blank" class="img-name" :href="record.evidence.url + '?' + Math.random">
                {{ record.evidence.name }}
              </a>
            </template>
            <template #totalcharge="{ record }">
              ${{ record.totalcharge }}
            </template>
            <template #estcharge="{ record }">
              <a-tag>${{ record.estcharge }}</a-tag>
            </template>
            <template #claimamount="{ record }">
              ${{ record.claimamount }}
            </template>
            <template #declaredvalue="{ record }">
              ${{ record.declaredvalue }}
            </template>
            <template #action="{ record }">
              <a-tooltip content="Edit">
                <a-button shape="circle" v-if="record.can_edit === 1" @click="goEdit(record.key)" class="m-r-8">
                  <icon-edit />
                </a-button>
              </a-tooltip>
              <a-tooltip content="Duplicate">
                <a-button shape="circle" @click="goDuplicate(record.key)" class="m-r-8">
                  <icon-copy />
                </a-button>
              </a-tooltip>
              <a-popconfirm content="Are you sure to cancel claim you selected?" type="warning" ok-text="Cancel claim(s)"
                cancel-text="Back" @ok="canClose(record.key)" class="warnPop" style="width:320px;">
                <a-tooltip content="Cancel">
                  <a-button shape="circle" v-if="record.can_close === 1" class="m-r-8">
                    <icon-stop />
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
              <a-tooltip content="Transaction">
                <a-button shape="circle" v-if="record.status === 3" @click="goTransaction(record.trackingid)">
                  <icon-right />
                </a-button>
              </a-tooltip>
            </template>
          </a-table>
        </a-config-provider>
        <div class="page" :style="selectArr.val.length > 0 ? 'padding-bottom:96px' : 'padding-bottom:0'">
          <a-button shape="round" type="primary" @click="$router.push('/usercenter/claim')">+ Add New Claim</a-button>
          <a-space>
            <span>Total : {{ claimTotal }} items</span>
            <a-button type="text" :disabled="currentpage == 1" @click="clickMinus">
              <icon-left />
            </a-button>
            <span class="currentpage">{{ currentpage }}</span>
            <div>/</div>
            <div>{{ totalPages }}</div>
            <a-button type="text" :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd">
              <icon-right />
            </a-button>
            <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
              <a-option :value="20">20 / Page</a-option>
              <a-option :value="50">50 / Page</a-option>
              <a-option :value="100">100 / Page</a-option>
              <a-option :value="200">200 / Page</a-option>
            </a-select>
          </a-space>
        </div>
      </div>
      <div class="addnew-footer" v-if="selectArr.val.length > 0">
        <a-space>
          <a-checkbox v-model="checkedValue" @change="changmsg" class="itemNum">
            {{ selectArr.val.length }} items
          </a-checkbox>
          <a-popconfirm content="Are you sure you want to duplicate?" type="warning" ok-text="Duplicate"
            cancel-text="Back" @ok="moreDuplicate" class="warnPop">
            <a-button size="large" v-if="selectArr.val.length > 0" shape="round">
              Duplicate
            </a-button>
          </a-popconfirm>
          <a-popconfirm content="Are you sure to cancel claims you selected?" type="warning" ok-text="Cancel claim(s)"
            cancel-text="Back" @ok="moreCancel" class="warnPop">
            <a-button shape="round" status="warning" v-if="cancelDisable === false && selectArr.val.length > 0">
              Cancel</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import local from '@/utils/local'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { claimList, claimClose, claimCopy } from '@/api/usercenter.js'
import { Notification, Message } from '@arco-design/web-vue'
import {
  IconRight, IconLeft, IconCopy, IconEdit, IconStop
} from '@arco-design/web-vue/es/icon'
import { useRouter, useRoute } from 'vue-router'
// 使用loading
import { load } from '@/utils/loading.js'
const claimStatus = JSON.parse(local.get('configInfo')).claim_status
const reason = JSON.parse(local.get('configInfo')).claim_reason
const loading = ref(false)
const formRef = ref(null)
const router = useRouter()
const route = useRoute()
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
const tableWidth = ref(0)
tableHeight.value = document.body.clientHeight - 460
tableWidth.value = document.body.clientWidth - 50
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableWidth.value = document.body.clientWidth - 50
    tableHeight.value = document.body.clientHeight - 460
  }
})
const form = reactive({
  status: ['1', '2', '3', '4', '6'],
  reason: [1, 2, 3, 4],
  tracking: '',
  createTime: ['', '']
})
const listColumns = [
  {
    title: 'Claim Status',
    dataIndex: 'claimstatus',
    width: 180,
    slotName: 'claimstatus',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Tracking ID',
    dataIndex: 'trackingid',
    width: 180,
    slotName: 'trackingid',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Create At',
    dataIndex: 'createat',
    width: 200,
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Evidence',
    dataIndex: 'evidence',
    width: 180,
    slotName: 'evidence',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Total Charge',
    dataIndex: 'totalcharge',
    width: 150,
    slotName: 'totalcharge',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Est. Charge',
    dataIndex: 'estcharge',
    width: 150,
    slotName: 'estcharge',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Claim Amount',
    dataIndex: 'claimamount',
    width: 160,
    slotName: 'claimamount',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Declared Value',
    dataIndex: 'declaredvalue',
    width: 160,
    slotName: 'declaredvalue',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    fixed: 'right',
    width: 160
  }
]
let selectArr = reactive({
  val: []
})
let claimId = ref(route.query.id)
console.log(claimId)
// 表格数据
let listData = reactive({ value: [] })
const claimTotal = ref(0)
const pageSize = ref(20)
const currentpage = ref(1)
// 总页数
const totalPages = ref(0)
const getList = async () => {
  loading.value = true
  const msg = await claimList({
    pageCount: pageSize.value,
    page: currentpage.value,
    claim_status: form.status,
    start: form.createTime[0],
    end: form.createTime[1],
    tracking_id: form.tracking,
    id: claimId.value
  })
  // console.log(msg)
  if (msg.code === 0) {
    loading.value = false
    claimTotal.value = msg.data.total
    currentpage.value = msg.data.current_page
    totalPages.value = msg.data.page_count
    let info = msg.data.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({
        key: info[i].id,
        status: info[i].claim_status,
        claimstatus: info[i].status_desc,
        reason: info[i].reason_desc,
        trackingid: info[i].tracking_id,
        createat: info[i].created_at,
        evidence: info[i].images[0],
        totalcharge: info[i].total_charge,
        estcharge: info[i].est_charge,
        claimamount: info[i].claim_amount,
        declaredvalue: info[i].insurance,
        can_close: info[i].can_close,
        can_edit: info[i].can_edit
      })
    }
    listData.value = currentData
    // console.log(listData.value)
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 表格渲染
getList()
// 表格复选框
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const checkedValue = ref(false)
const cancelDisable = ref(true)
// 多选
const changmsg = (checked) => {
  checkedValue.value = checked
  // console.log(checked)
  if (checked) {
    selectArr.val = []
    // 选中的所有key
    listData.value.forEach((item) => {
      selectArr.val.push(item.key)
    })
    // 判断是否可以做批量操作
    if (listData.value.every(item => { return item.can_close === 1 })) {
      cancelDisable.value = false
    }
  } else {
    selectArr.val = []
    cancelDisable.value = true
  }
}
// 单选
function selectVal (rowKeys) {
  // console.log(rowKeys)
  selectArr.val = rowKeys
  if (rowKeys.length === listData.value.length) {
    checkedValue.value = true
  } else {
    checkedValue.value = false
  }
  // 循环listData.value，选出选中的数组
  let newArr = []
  for (let i = 0; i < rowKeys.length; i++) {
    for (let j = 0; j < listData.value.length; j++) {
      if (rowKeys[i] === listData.value[j].key) {
        newArr.push(listData.value[j])
      }
    }
  }
  if (newArr.length > 0) {
    if (newArr.every(item => { return item.can_close === 1 })) {
      cancelDisable.value = false
    } else {
      cancelDisable.value = true
    }
  } else {
    cancelDisable.value = true
  }
}
// 搜索
const search = () => {
  currentpage.value = 1
  getList()
}
// 重置
const reset = () => {
  formRef.value.resetFields()
  currentpage.value = 1
  getList()
}
// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}
const selectPage = (val) => {
  pageSize.value = val
  getList()
}
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
const goEdit = (id) => {
  router.push('/usercenter/claimedit?editId=' + id + '&edit=' + 1)
}
const goDuplicate = (id) => {
  router.push('/usercenter/claimedit?editId=' + id + '&edit=' + 0)
}
const canClose = async (id) => {
  const msg = await claimClose({
    ids: [id]
  })
  // console.log(msg)
  if (msg.code === 0) {
    Notification.success('Canceled.')
    selectArr.val = []
    getList()
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}

const moreCancel = async () => {
  const msg = await claimClose({
    ids: selectArr.val
  })
  // console.log(msg)
  if (msg.code === 0) {
    Notification.success('Canceled.')
    selectArr.val = []
    getList()
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}

const goTransaction = (id) => {
  // console.log(id)
  router.push('/usercenter/transaction?track=' + id)
}

const moreDuplicate = async () => {
  // console.log(selectArr.val)
  load.show('Duplicating...')
  const msg = await claimCopy({
    ids: selectArr.val
  })
  // console.log(msg)
  if (msg.code === 0) {
    load.hide()
    Notification.success('Duplicated.')
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
</script>

<style lang="less" scoped>
.img-name {
  color: rgb(var(--primary-6));
  cursor: pointer;
  text-decoration: none;
}

.m-r-8 {
  margin-right: 8px;
}

.evidence {
  width: 64px;
  height: 64px;
}

.margin-l-10 {
  cursor: pointer;
  width: 12px;
  height: 12px;
  color: #C9CDD4;
  padding-left: 4px;
}

.claimlist-cont {
  position: relative;
}

.claim-title {
  font-weight: 500;
  font-size: 20px;
  padding: 18px 24px 18px 48px;
  color: var(--color-text-1);
}

.search-box {
  margin: 24px;
}

.operate {
  display: flex;
  // justify-content: space-around;
  align-items: flex-end;
  height: 82px;
}

.result-til {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-1);
  padding: 0 24px;
}

.table-data {
  margin: 24px;
}

.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 100px;
  margin-right: 20px;
  margin-top: 24px;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.addnew-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 20px;
  height: 72px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}
</style>
